function parametrageController(AppSettings, DatatableLang, $state, $scope, WS, $window, $http, $translate) {
    'ngInject';
    const vm = this;
    vm.current_date = moment().format('YYYY-MM');
    vm.cmd = {};
    vm.cmd.prod = [];
    vm.keymaps = AppSettings.keymaps;
    vm.fiche = [];
    vm.currentLang = $translate.proposedLanguage() || $translate.use();
    vm.soussocietes = [];
    vm.allsocietes = {};
    vm.new_product = {};
    vm.gratuites = [];
    WS.get('pays/getFirstpays').then(
        function (response) {
            vm.pays = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log('error : ->', error);
        }
    );

    WS.getScache('societes')
        .then(function (response) {
            vm.societes = response.data[0];
            var contrat_code = vm.societes.contrat;
            /*console.log(response);
            console.log(contrat_code);*/
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                cache: false
            }).then(function successCallback(response) {
                vm.global_gestion_stock = response.data.gestion_stock;
                console.log('vm.global_gestion_stock params', vm.global_gestion_stock);
            }, function errorCallback(response) {
            });
            $scope.$apply();

        })
        .then(null, function (error) {
            console.log(error);
        });

    vm.updateCodeBl = function (code) {
        swal({
            title: 'Suppresion Document',
            text: "",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer !',
            cancelButtonText: 'Non, Annuler!',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function () {

            WS.get('entetecommercial/delete/' + code)
                .then(function (response) {
                    swal(
                        'Succès',
                        'Document supprimée avec Succès',
                        'Succès'
                    ).then(function () {
                        $window.location.reload();
                    });
                })
                .then(null, function (error) {
                    console.log(error);
                });
        });
    };

    vm.getEcheances = function () {
        WS.get('dashboard/echeances', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.echeances = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang,
                        order: [
                            [1, 'DESC']
                        ]
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.getvisiteHorsClient = function () {
        WS.getData('dashboard/visiteHorsClient')
            .then(function (response) {
                if (response.status == 200) {
                    var longitudes = 0;
                    var latitudes = 0;
                    _.each(response.data, element => {
                        longitudes += parseFloat(element.longClient);
                        latitudes += parseFloat(element.latClient);
                    });
                    longitudes = longitudes / response.data.length;
                    latitudes = latitudes / response.data.length;
                    vm.centerrapvisite = `${latitudes}, ${longitudes}`;

                    vm.visiteHorsClient = response.data;
                    console.log(vm.centerrapvisite);


                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    

    vm.getcreditencours = function (commercial) {
        var user_code = $state.params.user_code;
        if(commercial){
            if(commercial == 'Tous'){
                var data = { commercial: null }
            }else{
                var data = { commercial: commercial }
            }
        }else{
            if(user_code){
                vm.encours = {};
                vm.encours.commercial = user_code;
                var data = { commercial: user_code }
            }else{
                vm.encours = {};
                vm.encours.commercial = 'Tous';
                var data = { commercial: null }
            }
        }

        WS.getData('rapports/creditEncoursParCommercial', data)
            .then(response => {
                vm.creditencours = [];
                vm.list_of_credit = [];
                vm.total_of_credit = 0;
                _.each(response.data, element => {
                    _.each(element.list_client_with_credit, list => {
                        vm.creditencours.push({
                            user_code : element.user_code,
                            user : element.user,
                            user_id : element.user,
                            encours: list.encours,
                            id: list.id,
                            mobile: list.mobile,
                            routing_code: list.routing_code,
                            adresse_facturation: list.adresse_facturation,
                            client_code: list.client_code,
                            client: list.client,
                            magasin: list.magasin
                        });

                        vm.total_of_credit +=list.encours;
                    })
                });

                $scope.$apply();
            });
    };

    // vm.initgetcreditencours = function () {
    //     var user_code = $state.params.user_code;
    //     if (user_code) {
    //         vm.encours = {};
    //         vm.encours.commercial = user_code;
    //         var data = { commercial: user_code }
    //         WS.getData('rapports/creditEncoursParCommercial', data)
    //             .then(response => {
    //                 vm.creditencours = response.data;

    //                 $scope.$apply();
    //             });
    //     }
    // };


    vm.exportcreditencours = function (commercial) {
        var user_code = $state.params.user_code;
        if(commercial){
            if(commercial == 'Tous'){
                var data = { commercial: null }
            }else{
                var data = { commercial: commercial }
            }
        }else{
            if(user_code){
                vm.encours = {};
                vm.encours.commercial = user_code;
                var data = { commercial: user_code }
            }else{
                vm.encours = {};
                vm.encours.commercial = 'Tous';
                var data = { commercial: null }
            }
        }
        WS.getData('rapports/creditEncoursParCommercial', data)
            .then(response => {

                vm.creditencours = [];
                vm.list_of_credit = [];
                vm.total_of_credit = 0;
                _.each(response.data, element => {
                    _.each(element.list_client_with_credit, list => {
                        vm.creditencours.push({
                            user_code : element.user_code,
                            user : element.user,
                            user_id : element.user,
                            encours: list.encours,
                            id: list.id,
                            mobile: list.mobile,
                            routing_code: list.routing_code,
                            adresse_facturation: list.adresse_facturation,
                            client_code: list.client_code,
                            client: list.client,
                            magasin: list.magasin
                        });

                        vm.total_of_credit +=list.encours;
                    })
                });                var filename = 'Credit Encours-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                // var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;
                
                var file = {
                    sheetid: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
                    headers: true,
                    caption: {
                        title: 'Total des credit :' + vm.total_of_credit,
                        style: 'font-size: 50px; color:blue;'
                    },
                    column: {
                        style: 'font-size:20px;background:#d1d1d1;'
                    },
                    columns: [

                        { columnid: 'client_code', title: 'Code' },
                        { columnid: 'client', title: 'Client' },
                        { columnid: 'magasin', title: 'Magasin' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'mobile', title: 'Mobile' },
                        { columnid: 'routing_code', title: 'Route' },
                        { columnid: 'user', title: 'Commercial' },
                        { columnid: 'encours', title: 'Encours' }

                    ],
                    row: {
                        style: function (sheet, row, rowidx) {
                            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                        }
                    }
                };
                vm.creditencours.forEach(function (element, index) {
                    vm.creditencours[index].encours = vm.creditencours[index].encours.toString().replace('.', ',');
                });
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.creditencours]);
                alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [file, vm.creditencours]);
            },
                function (error) {
                    console.log(error);
                }
            );
    };

    vm.getTotalCredit = function (list, attribut = 'encours') {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i][attribut]);
        }
        return total;
    };

    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                vm.selectgammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.getnvclients = function () {
        WS.get('clients/nvClients')
            .then(function (response) {
                vm.nvclients = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.kpi_dd = function () {
        _.mixin({
            capitalize: function (string) {
                return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
            }
        });
        var monthname = moment(vm.month).format('MMMM');
        vm.monthname = _(monthname).capitalize();
        vm.yearname = moment(vm.month).format('YYYY');
        vm.startOfMonth = moment(vm.month).startOf('month').format('YYYY-MM-DD');
        vm.endOfMonth = moment(vm.month).endOf('month').format('DD');
        vm.alldays = [];
        var countOutDim = 0;
        for (var i = 1; i <= Number(vm.endOfMonth); i++) {
            i = (i <= 9) ? '0' + i : i;
            vm.alldays.push({
                day: i,
                name: moment(vm.month + '-' + i).format('ddd')
            });
            if (moment(vm.month + '-' + i).format('dddd') != 'dimanche') {
                countOutDim++;
            }

        }
        vm.countOutDim = countOutDim;

        $(".datepicker-dashb").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.month = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });

        //console.log(vm.month, vm.kpi_commercial);
    };

    vm.kpi_objectifs = function () {
        WS.getScache('rapports/ObejectifsByCommercialsMonth' + `?commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
            vm.tableobjectifs = [];
            response.data.forEach(function (element, index) {
                var date_debut = element.date_debut;
                var date_fin = element.date_fin;
                var days_objs = [];
                var nbr_jour_travaille = 0;
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
                    var day_obj = moment(vm.month + '-' + i).format('dddd');
                    if (
                        (compareDate.isBetween(element.date_debut, element.date_fin) ||
                            compareDate.isSame(element.date_debut) ||
                            compareDate.isSame(element.date_fin)) && day_obj != 'dimanche') {


                        days_objs.push({
                            chiffre: element.chiffre,
                            value: element.obj_visite,
                            day: day_obj,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                        nbr_jour_travaille++;

                    } else {
                        days_objs.push({
                            chiffre: 0,
                            value: 0,
                            day: day_obj,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                    }
                }
                vm.tableobjectifs.push({
                    code: element.code,
                    nom: element.nom,
                    prenom: element.prenom,
                    days_objs: days_objs,
                    total: days_objs.reduce((s, f) => s + f.value, 0),
                    totalchiffre: days_objs.reduce((s, f) => s + f.chiffre, 0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });

            var tabtotal = [];
            var tabtotalchiffre = [];
            vm.tableobjectifs.forEach(function (element, index) {
                element.days_objs.forEach(function (obj, ind) {
                    tabtotal[ind] = (tabtotal[ind]) ? tabtotal[ind] + obj.value : obj.value;
                    tabtotalchiffre[ind] = (tabtotalchiffre[ind]) ? tabtotalchiffre[ind] + obj.chiffre : obj.chiffre;
                });
            });

            vm.tabtotal = tabtotal;


            vm.tabtotalchiffre = tabtotalchiffre;

            vm.totalend = tabtotal.reduce((s, f) => s + f, 0);
            vm.totalendchiffre = tabtotalchiffre.reduce((s, f) => s + f, 0);

            vm.total_nbr_jour_trav = vm.tableobjectifs.reduce((s, f) => s + f.nbr_jour_travaille, 0);
            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpi_visites = function () {

        WS.getScache('rapports/kpi_visites' + `?commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
            var tabvisites = [];
            response.data.users.forEach(function (user, indUser) {
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.visites.forEach(function (visite, indVisite) {
                    if (user.code == visite.user_code) {
                        tabusers.push({
                            date_visite: visite.date_visite,
                            nbr_visite: visite.nbr_visite,
                            day: moment(visite.date_visite).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });

                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    tabusers.forEach(function (element, index) {
                        if (element.date_visite == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_visite: date_trait,
                            nbr_visite: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_visite).getTime());
                });
                tabvisites.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    visites: tabusers,
                    total: tabusers.reduce((s, f) => s + f.nbr_visite, 0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabvisites = tabvisites;
            var totalendObjs = 0;
            vm.tabvisites.forEach(function (visite, indexVis) {
                vm.tableobjectifs.forEach(function (objectif, indexObj) {
                    if (visite.code == objectif.code) {
                        var filteredItems = _.filter(visite.visites, function (item, i) {
                            return item.nbr_visite != 0;
                        });

                        var dates = _.pluck(filteredItems, 'date_visite');

                        var totobjres = 0;

                        _.each(objectif.days_objs, function (el, index) {
                            //console.log(_.contains(dates, el.date_visite), dates, el.date_visite);
                            if (_.contains(dates, el.date_visite) == true) {
                                totobjres += Number(el.value);
                            }
                        });

                        vm.tabvisites[indexVis].totalObjRealise = totobjres;
                        totalendObjs += totobjres;
                    }
                });
            });

            console.log('-_-_-_-_-_-_-__-_-__-_-_-_-_-_-_-_-_-_--__-');
            console.log('tabvisites', vm.tabvisites);
            console.log('-_-_-_-_-_-_-__-_-__-_-_-_-_-_-_-_-_-_--__-');

            var tabtotalVisite = [];
            vm.tabvisites.forEach(function (element, index) {
                element.visites.forEach(function (obj, ind) {
                    tabtotalVisite[ind] = (tabtotalVisite[ind]) ? tabtotalVisite[ind] + obj.nbr_visite : obj.nbr_visite;
                });
            });
            vm.tabtotalVisite = tabtotalVisite;
            vm.totalendVisite = tabtotalVisite.reduce((s, f) => s + f, 0);
            vm.totalendVisite = tabtotalVisite.reduce((s, f) => s + f, 0);

            vm.total_nbr_jour_visite = vm.tabvisites.reduce((s, f) => s + f.nbr_jour_travaille, 0);
            vm.totalendObjs = totalendObjs;

            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpi_ventes = function () {
        WS.getScache(
            'rapports/kpi_ventes' + `?commercial=${vm.kpi_commercial}&month=${vm.month}&gamme_code=${vm.kpi_gamme}&produit_code=${vm.kpi_produit}`
        ).then(function (response) {
            var tabventes = [];
            response.data.users.forEach(function (user, indUser) {
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.ventes.forEach(function (vente, indvente) {
                    if (user.code == vente.user_code) {
                        tabusers.push({
                            date_vente: vente.date_vente,
                            nbr_vente: vente.nbr_vente,
                            day: moment(vente.date_vente).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });

                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    tabusers.forEach(function (element, index) {
                        if (element.date_vente == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_vente: date_trait,
                            nbr_vente: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_vente).getTime());
                });
                tabventes.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    ventes: tabusers,
                    total: tabusers.reduce((s, f) => s + f.nbr_vente, 0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabventes = tabventes;

            var tabtotalvente = [];
            vm.tabventes.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {
                    if (tabtotalvente[ind]) {
                        tabtotalvente[ind].nbr_vente = (tabtotalvente[ind].nbr_vente) ? tabtotalvente[ind].nbr_vente + obj.nbr_vente : obj.nbr_vente;
                    } else {
                        tabtotalvente.push({ nbr_vente: obj.nbr_vente, date_vente: obj.date_vente });

                    }
                });
            });
            vm.tabtotalvente = tabtotalvente;
            vm.totalendvente = tabtotalvente.reduce((s, f) => s + f.nbr_vente, 0);

            vm.total_nbr_jour_vente = vm.tabventes.reduce((s, f) => s + f.nbr_jour_travaille, 0);
            // console.log('tabtotalvente', tabtotalvente);
            // console.log('totalendvente', vm.totalendvente);

            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpi_total_ttc = function () {
        WS.getScache('rapports/kpi_total_ttc' + `?commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
            var tabttc = [];
            response.data.users.forEach(function (user, indUser) {
                var tabusers = [];
                var nbr_jour_travaille = 0;
                response.data.ventes.forEach(function (vente, indvente) {
                    if (user.code == vente.user_code) {
                        tabusers.push({
                            date_vente: vente.date_vente,
                            total_ttc: parseFloat(vente.total_ttc),
                            day: moment(vente.date_vente).format('dddd')
                        });
                        nbr_jour_travaille++;
                    }
                });
                //tabusers
                //traitement with kpi_dd global
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var date_trait = vm.month + '-' + i;
                    var j = 0;
                    tabusers.forEach(function (element, index) {
                        if (element.date_vente == date_trait) {
                            j++;
                        }
                    });
                    if (j == 0) {
                        tabusers.push({
                            date_vente: date_trait,
                            total_ttc: 0,
                            day: moment(date_trait).format('dddd')
                        });
                    }
                }

                tabusers = _.sortBy(tabusers, function (node) {
                    return (new Date(node.date_vente).getTime());
                });
                var total = tabusers.reduce((s, f) => s + f.total_ttc, 0);
                tabttc.push({
                    code: user.code,
                    nom: user.nom,
                    prenom: user.prenom,
                    ventes: tabusers,
                    total: parseFloat(total).toFixed(0),
                    nbr_jour_travaille: nbr_jour_travaille
                });
            });
            vm.tabttc = tabttc;

            var tabtotalttc = [];
            vm.tabttc.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {
                    tabtotalttc[ind] = (tabtotalttc[ind]) ? parseFloat(tabtotalttc[ind]) + obj.total_ttc : obj.total_ttc;
                    tabtotalttc[ind] = parseFloat(tabtotalttc[ind]).toFixed(0);
                });
            });
            vm.tabtotalttc = tabtotalttc;

            var totalendttc = tabtotalttc.reduce((s, f) => parseFloat(s) + parseFloat(f), 0);
            vm.totalendttc = parseFloat(totalendttc).toFixed(0);

            vm.total_nbr_jour_ttc = vm.tabttc.reduce((s, f) => s + f.nbr_jour_travaille, 0);

            //vm.tabtotalttcPercent //ttc percent
            /**
             * debut percent vente ttc
             */
            var tabtotalttcPercent = [];
            vm.tabttc.forEach(function (element, index) {
                element.ventes.forEach(function (obj, ind) {

                    vm.tabtotalttc.forEach(function (e_total, i_total) {
                        if (i_total == ind) {
                            if (e_total > 0) {
                                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + (obj.total_ttc * 100 / e_total) : (obj.total_ttc * 100 / e_total);
                                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
                            } else {
                                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + 0 : 0;
                                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
                            }
                        }
                    });


                });
            });
            vm.tabtotalttcPercent = tabtotalttcPercent;
            var totalendttcPercent = tabtotalttcPercent.reduce((s, f) => parseFloat(s) + parseFloat(f), 0) / tabtotalttcPercent.length;
            vm.totalendttcPercent = parseFloat(totalendttcPercent).toFixed(0);

            /**
             * fin percent vente ttc
             */


            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpiChartLinettc = function () {
        WS.getScache('rapports/kpiChartLinettc' + `?commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`
        ).then(function (response) {
            var tabusers = [];
            response.data.ventes.forEach(function (vente, indvente) {
                tabusers.push({
                    date_vente: vente.date_vente,
                    total_ttc: vente.total_ttc,
                    day: moment(vente.date_vente).format('DD ddd')
                });

            });

            //tabusers
            //traitement with kpi_dd global
            for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                i = (i <= 9) ? '0' + i : i;
                var date_trait = vm.month + '-' + i;
                var j = 0;
                tabusers.forEach(function (element, index) {
                    if (element.date_vente == date_trait) {
                        j++;
                    }
                });
                if (j == 0) {
                    tabusers.push({
                        date_vente: date_trait,
                        total_ttc: 0,
                        day: moment(date_trait).format('DD ddd')
                    });
                }
            }

            tabusers = _.sortBy(tabusers, function (node) {
                return (new Date(node.date_vente).getTime());
            });


            /**
             * traitement d'objectif 
             */
            vm.traitementobjectif = [];
            response.data.objectifs.forEach(function (element, index) {
                var date_debut = element.date_debut;
                var date_fin = element.date_fin;
                var days_objs = [];
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
                    var day_obj = moment(vm.month + '-' + i).format('dddd');
                    if (
                        (compareDate.isBetween(element.date_debut, element.date_fin) ||
                            compareDate.isSame(element.date_debut) ||
                            compareDate.isSame(element.date_fin)) && day_obj != 'dimanche') {

                        days_objs.push({
                            chiffre: element.chiffre,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                    } else {
                        days_objs.push({
                            chiffre: 0,
                            date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
                        });
                    }
                }
                vm.traitementobjectif.push({
                    days_objs: days_objs
                });
            });

            var tabtotalchiffre = [];
            vm.traitementobjectif.forEach(function (element, index) {
                element.days_objs.forEach(function (obj, ind) {
                    tabtotalchiffre[ind] = (tabtotalchiffre[ind]) ? tabtotalchiffre[ind] + obj.chiffre : obj.chiffre;
                });
            });
            vm.totalendchiffre = tabtotalchiffre.reduce((s, f) => s + f, 0);

            vm.moyennetotalchiffre = vm.totalendchiffre / vm.tabtotalchiffre.length;
            /** 
             * fin du traitement 
             */

            tabusers.forEach(function (element, index) {
                tabtotalchiffre.forEach(function (totalchiffre, indexchiffre) {
                    if (index == indexchiffre) {
                        tabusers[index].objectif = totalchiffre;
                    }
                });
            });
            vm.chartlinettc = tabusers;
            /*console.log('vm.chartlinettc', vm.chartlinettc);
            console.log('vm.tabtotalchiffre', tabtotalchiffre);*/

            var chart = AmCharts.makeChart("chartlinettc", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": true,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": vm.chartlinettc,
                "valueAxes": [{
                    "axisAlpha": 0,
                    "position": "left",
                    "guides": [{
                        "fillAlpha": 0.1,
                        "label": vm.moyennetotalchiffre.toFixed(3),
                        "value": vm.moyennetotalchiffre,
                        "toValue": vm.moyennetotalchiffre,
                        "lineColor": "#ff0000",
                        "lineAlpha": 2,
                        "fillAlpha": 0.2,
                        "fillColor": "#FFCC00",
                        "inside": false
                    }]

                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "CA (TTC)",
                    "type": "column",
                    "valueField": "total_ttc",
                    "dashLengthField": "dashLengthColumn"
                }, {
                    "id": "graph2",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "bullet": "round",
                    "lineThickness": 3,
                    "bulletSize": 7,
                    "bulletBorderAlpha": 1,
                    "bulletColor": "#FFFFFF",
                    "useLineColorForBulletBorder": true,
                    "bulletBorderThickness": 3,
                    "fillAlphas": 0,
                    "lineAlpha": 1,
                    "title": "Objectif",
                    "valueField": "objectif",
                    "dashLengthField": "dashLengthLine"
                }],
                "categoryField": "day",
                "categoryAxis": {
                    /*"gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0*/

                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "fillAlpha": 0.01, //default 0.05
                    "fillColor": "#000000",
                    "gridAlpha": 0,
                    "position": "bottom"
                },
                "export": {
                    "enabled": true
                }
            });

            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.kpiChartLineTauxSuccess = function () {
        WS.getScache('rapports/kpiChartLineTauxSuccess' + `?commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
            var data = [];
            response.data.visite.forEach(function (visite, indexVis) {

                response.data.vente.forEach(function (vente, indexVen) {
                    if (visite.date_visite == vente.date_visite) {
                        var obj = {};
                        obj = {
                            date_visite: vente.date_visite,
                            day: moment(vente.date_visite).format('DD ddd'),
                            taux: parseFloat(vente.nbr_vente * 100 / visite.nbr_total).toFixed(0)
                        }
                        data.push(obj);
                    }
                });
            });

            //traitement with kpi_dd global
            for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                i = (i <= 9) ? '0' + i : i;
                var date_trait = vm.month + '-' + i;
                var j = 0;
                data.forEach(function (element, index) {
                    if (element.date_visite == date_trait) {
                        j++;
                    }
                });
                if (j == 0) {
                    data.push({
                        date_visite: date_trait,
                        day: moment(date_trait).format('DD ddd'),
                        taux: 0
                    });
                }
            }
            vm.totalendtaux_success = data.reduce((s, f) => parseFloat(s) + parseFloat(f.taux), 0);

            vm.moyennetaux_success = vm.totalendtaux_success / data.length;
            //console.log(vm.totalendtaux_success, vm.moyennetaux_success );
            var data = _.sortBy(data, 'date_visite');

            var chart = AmCharts.makeChart("kpiChartLineTauxSuccess", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": true,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": data,
                "valueAxes": [{
                    "max": 100,
                    "maximum": 100,
                    "axisAlpha": 0,
                    "position": "left",
                    "guides": [{
                        "fillAlpha": 0.1,
                        "label": vm.moyennetaux_success.toFixed(2),
                        "value": vm.moyennetaux_success,
                        "toValue": vm.moyennetaux_success,
                        "lineColor": "#ff0000",
                        "lineAlpha": 2,
                        "fillAlpha": 0.2,
                        "fillColor": "#FFCC00",
                        "inside": false
                    }]

                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "Taux de succès (%)",
                    "type": "column",
                    "valueField": "taux",
                    "dashLengthField": "dashLengthColumn"
                }, {
                    "id": "graph2",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "bullet": "round",
                    "lineThickness": 3,
                    "bulletSize": 7,
                    "bulletBorderAlpha": 1,
                    "bulletColor": "#FFFFFF",
                    "useLineColorForBulletBorder": true,
                    "bulletBorderThickness": 3,
                    "fillAlphas": 0,
                    "lineAlpha": 1,
                    "title": "Objectif",
                    "valueField": "objectif",
                    "dashLengthField": "dashLengthLine"
                }],
                "categoryField": "day",
                "categoryAxis": {
                    /*"gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0*/

                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "fillAlpha": 0.01, //default 0.05
                    "fillColor": "#000000",
                    "gridAlpha": 0,
                    "position": "bottom"
                },
                "export": {
                    "enabled": true
                }
            });
            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };

    vm.selectProduits = function () {
        WS.get('produits/selectProduit')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.kpiChartLineTauxVisites = function () {
        WS.getScache('rapports/kpiChartLineTauxVisites' + `?commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {

            var objectifs = [];

            response.data.objectifs.forEach(function (element, index) {
                var date_debut = element.date_debut;
                var date_fin = element.date_fin;
                for (var i = 1; i <= Number(vm.endOfMonth); i++) {
                    i = (i <= 9) ? '0' + i : i;
                    var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
                    var date_trait = moment(vm.month + '-' + i).format("YYYY-MM-DD");
                    var is_dim = moment(vm.month + '-' + i).format('dddd');
                    var day_obj = moment(vm.month + '-' + i).format('DD ddd');
                    var j = 0;
                    if (
                        (compareDate.isBetween(element.date_debut, element.date_fin) ||
                            compareDate.isSame(element.date_debut) ||
                            compareDate.isSame(element.date_fin)) && is_dim != 'dimanche') {
                        objectifs.forEach(function (objectif, indexObj) {
                            if (objectif.date_visite == date_trait) {
                                j++;
                                objectifs[indexObj].taux = parseFloat(objectif.taux) + element.obj_visite;
                            }
                        });
                        if (j == 0) {
                            objectifs.push({
                                taux: element.obj_visite,
                                date_visite: date_trait,
                                day: day_obj
                            });
                        }

                    } else {

                        objectifs.forEach(function (objectif, indexObj) {
                            if (objectif.date_visite == date_trait) {
                                j++;
                            }
                        });
                        if (j == 0) {
                            objectifs.push({
                                taux: 0,
                                date_visite: date_trait,
                                day: day_obj
                            });
                        }

                    }
                }

            });

            objectifs.forEach(function (element, index) {
                var i = 0;
                response.data.visite.forEach(function (visite, indexVis) {
                    if (element.date_visite == visite.date_visite) {
                        if (element.taux == 0 || visite.nbr_total == 0) {
                            var taux = 0;
                        } else {
                            var taux = visite.nbr_total * 100 / element.taux;
                            if (taux > 100) {
                                taux = 100;
                            }
                        }
                        objectifs[index].taux = taux.toFixed(0);
                        i++;
                    }
                });
                if (i == 0) {
                    objectifs[index].taux = 0;
                }
            });
            vm.totalendtaux_visite = objectifs.reduce((s, f) => parseFloat(s) + parseFloat(f.taux), 0);

            vm.moyennetaux_visite = vm.totalendtaux_visite / objectifs.length;
            //console.log(vm.totalendtaux_success, vm.moyennetaux_success );
            var chart = AmCharts.makeChart("kpiChartLineTauxVisites", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": true,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": objectifs,
                "valueAxes": [{
                    "max": 100,
                    "maximum": 100,
                    "axisAlpha": 0,
                    "position": "left",
                    "guides": [{
                        "fillAlpha": 0.1,
                        "label": vm.moyennetaux_visite.toFixed(2),
                        "value": vm.moyennetaux_visite,
                        "toValue": vm.moyennetaux_visite,
                        "lineColor": "#ff0000",
                        "lineAlpha": 2,
                        "fillAlpha": 0.2,
                        "fillColor": "#FFCC00",
                        "inside": false
                    }]

                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "Taux de Visite (%)",
                    "type": "column",
                    "valueField": "taux",
                    "dashLengthField": "dashLengthColumn"
                }],
                "categoryField": "day",
                "categoryAxis": {
                    /*"gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0*/

                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "fillAlpha": 0.01, //default 0.05
                    "fillColor": "#000000",
                    "gridAlpha": 0,
                    "position": "bottom"
                },
                "export": {
                    "enabled": true
                }
            });
            $scope.$apply();
        },
            function (error) {
                console.log('error');
            });
    };




    vm.getCredits = function () {
        WS.get('dashboard/credits')
            .then(function (response) {
                if (response.status == 200) {
                    vm.credits = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang,
                        order: [
                            [1, 'DESC']
                        ]
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getFactures = function () {
        WS.get('dashboard/factures', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.factures = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang,
                        order: [
                            [1, 'DESC']
                        ]
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getMsgPromo = function () {
        WS.get('msg_promo')
            .then(function (response) {
                if (response.status == 200) {

                    vm.msg_promo = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang,
                        order: [
                            [1, 'DESC']
                        ]
                    });
                } else {
                    console.log('error', 422);
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.ajouterMsgPromo = function (msgpromo) {
        WS.post('msg_promo', msgpromo)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Msg ajouté avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.updateMsgPromo = function () {
        WS.put('msg_promo/' + vm.promoupdate.id, vm.promoupdate)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Message Promo modifié avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.deleteMsgPromo = function (id) {
        swal({
            title: 'Suppresion Message',
            text: "",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer !',
            cancelButtonText: 'Non, Annuler!',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function () {
            WS.delete('msg_promo/' + id)
                .then(function (response) {
                    swal({
                        title: 'Succès',
                        text: "Message supprimée avec Succès",
                        type: 'success',
                        showCancelButton: false,
                    }).then(function () {
                        $window.location.reload();
                    });
                })
                .then(null, function (error) {
                    console.log(error);
                });
        });

    };

    vm.getParametrage = function () {
        WS.get('parametrages/getIndexForDashboard', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.parametrages = response.data;
                    console.log(vm.parametrages);
                    vm.parametrages.photo_bl_avoir = vm.parametrages.photo_bl_avoir.toString();
                    vm.parametrages.retour_sans_chargement = vm.parametrages.retour_sans_chargement.toString();
                    vm.parametrages.retenue = vm.parametrages.retenue.toString();
                    vm.parametrages.mobile_double = vm.parametrages.mobile_double.toString();
                    vm.parametrages.edition_avoir = vm.parametrages.edition_avoir.toString();
                    vm.parametrages.fiche_article = vm.parametrages.fiche_article.toString();
                    vm.parametrages.accept_refus_total = vm.parametrages.accept_refus_total.toString();
                    vm.parametrages.ajout_commande_hors_client = vm.parametrages.ajout_commande_hors_client.toString();
                    vm.parametrages.recouvrement_total = vm.parametrages.recouvrement_total.toString();
                    vm.parametrages.obligation_gps = vm.parametrages.obligation_gps.toString();
                    vm.parametrages.affichage_list_prix = vm.parametrages.affichage_list_prix.toString();
                    vm.parametrages.quota_commande = vm.parametrages.quota_commande.toString();
                    vm.parametrages.affichage_prix_ht = vm.parametrages.affichage_prix_ht.toString();
                    vm.parametrages.remise_entete_avec_paiement = vm.parametrages.remise_entete_avec_paiement.toString();
                    vm.parametrages.choix_remise_par_commercial = vm.parametrages.choix_remise_par_commercial.toString();
                    vm.parametrages.obligation_photo_visite = vm.parametrages.obligation_photo_visite.toString();
                    vm.parametrages.signature_client = vm.parametrages.signature_client.toString();
                    vm.parametrages.affichage_list_produit = vm.parametrages.affichage_list_produit.toString();
                    vm.parametrages.choix_imprimante = vm.parametrages.choix_imprimante.toString();
                    vm.parametrages.use_quota = vm.parametrages.use_quota.toString();
                    vm.use_quota = vm.parametrages.use_quota;
                    vm.parametrages.validation_cadeaux = vm.parametrages.validation_cadeaux.toString();
                    vm.parametrages.choix_categorie_client = vm.parametrages.choix_categorie_client.toString();
                    vm.parametrages.change_prix_cmd = vm.parametrages.change_prix_cmd;
                    
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCommandes = function () {
        WS.getData('dashboard/commandes', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.commandes = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang,
                        order: [
                            [1, 'DESC']
                        ]
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getDemandePlafond = function () {
        WS.getData('dashboard/plafond_clients', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.plafond_clients = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang,
                        order: [
                            [1, 'DESC']
                        ]
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.cloture = function (id, result,client_code) {
        vm.msg='';
        if(result=='1'){
            vm.msg="Accepter cette demande ";
        }else{
            vm.msg="Refuser cette demande ";
        }
        swal({
            title: '',
            text: $translate.instant("Voulez vous vraiment "+vm.msg +" !"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
                WS.get('demande_exception_plafond/cloture/' + id +'/'+result+'/'+client_code ).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant("Operation faite avec succé"),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                }
            );
        }, function (error) {
            console.log(error);
        });
    };


    vm.getDemandeConge = function () {
        WS.getData('demande_conge/notification', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.demande_conges = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang,
                        order: [
                            [1, 'DESC']
                        ]
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.validOrRefuseConge = function (id, result,client_code) {
        vm.msg='';
        if(result=='2'){
            vm.msg="Accepter cette demande ";
        }else{
            vm.msg="Refuser cette demande ";
        }
        swal({
            title: '',
            text: $translate.instant("Voulez vous vraiment "+vm.msg +" !"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
                WS.put('demande_conge/acceptConge/' + id ).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant("Operation faite avec succé"),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                }
            );
        }, function (error) {
            console.log(error);
        });
    };


    vm.getDemandesAvoir = function () {
        WS.get('dashboard/demandesAvoirs', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.demandesAvoirs = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang,
                        order: [
                            [1, 'DESC']
                        ]
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getDetailsbyCode = function (code) {
        vm.cmd = {};
        vm.cmd.prod = [];
        vm.total_montant_ttc = 0;
        vm.total_net_a_payer = 0;
        vm.total_remises = 0;
        var url = 'entetecommercial/code/' + $state.params.id;
        WS.getData(url)
            .then(function (response) {

                vm.cmd = {};
                vm.cmd.prod = [];
                vm.details = response.data;
                vm.cmd.date_prevu_livraison = vm.details.date_prevu_livraison;
                vm.cmd.remiseEspece = (vm.details.remiseEspece) ? Number(vm.details.remiseEspece) : 0;
                console.log('vm.cmd.remiseEspece', vm.cmd.remiseEspece);
                vm.cmd.date = vm.details.date;
                vm.cmd.code = vm.details.code;
                vm.count_commentaire = 0;
                _.each(vm.details.paiements, (element, key) => {
                    if (element.commentaire != "") {
                        vm.count_commentaire++;
                    };
                });
                //console.log(vm.details.lignes);
                vm.total_montant_ttc = 0;
                vm.total_net_a_payer = 0;
                vm.total_remises = 0;
                _.each(vm.details.lignes, (element, key) => {
                   

                    if(element.type != 'gratuite'){
                        vm.total_remises += ((element.pu_ttc * element.quantite) * element.remise) / 100;
                        vm.total_montant_ttc += element.pu_ttc * element.quantite;
                        vm.total_net_a_payer += (element.pu_ttc * element.quantite - ((element.pu_ttc * element.quantite) * element.remise) / 100);
                      
                    }
                   // console.log(element.produit_code, element.stock_depot.quantite);
                    vm.cmd.prod.push({
                        quantite: element.quantite,
                        produit_code: element.produit_code,
                        entetecommercial_code: element.entetecommercial_code,
                        produit_libelle: element.produit_libelle,
                        pu_ttc: element.pu_ttc,
                        pu_ht: element.pu_ht,
                        remise: element.remise,
                        stock: (element.stock_depot) ? element.stock_depot.quantite : 0,
                        colisage: element.colisage,
                        type: element.type
                    });

                });
                
               // vm.somme_en_lettre = vm.ConvNumberLetter_fr(vm.total_net_a_payer, false);

                vm.getcreditencoursParams();
                vm.getPlafondCreditByClient ();
                $scope.$apply();


            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getcreditencoursParams = function () {
        //console.log('client_id', vm.details.client.id);
        WS.getData('rapports/creditEncoursParClientId/' + vm.details.client.id)
            .then(response => {
                vm.creditencours = response.data;
                vm.total_sold_commercial =  _.reduce(vm.creditencours, function(memo, num){ return memo + num.sold_commercial; }, 0);

                $scope.$apply();
            });
    };

    vm.getPlafondCreditByClient = function () {

        WS.getData('mouvement_clients/getPlafondCreditByClient' , {id : vm.details.client.id})
            .then(function (response) {
                if (response.status == 200) {
                    vm.plafondByUser = response.data;
                    console.log(vm.plafondByUser);
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getTotalCreditParams = function (list, attribut) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i][attribut]);
        }
        return total;
    }

    vm.recalculTotal = function () {
        vm.total_montant_ttc = 0;
        vm.total_net_a_payer = 0;
        vm.total_remises = 0;
        _.each(vm.cmd.prod, (element, key) => {
            vm.total_montant_ttc += element.pu_ttc * element.quantite;
            vm.total_net_a_payer += (element.pu_ttc * element.quantite - ((element.pu_ttc * element.quantite) * element.remise) / 100);
            vm.total_remises += ((element.pu_ttc * element.quantite) * element.remise) / 100;
        });
        vm.total_net_a_payer = vm.total_net_a_payer - Number(vm.cmd.remiseEspece);
        vm.somme_en_lettre = vm.ConvNumberLetter_fr(vm.total_net_a_payer, true);
    };

    vm.updateDateLivraisonCMD = function () {
      
        var authorize_call = 0;
        if (vm.global_gestion_stock == 1) {
            _.each(vm.cmd.prod, (element, key) => {
                if (element.stock < element.quantite) {
                    authorize_call++;
                }
            });
            
            if (authorize_call == 0) {
                WS.post('commandes/clotureDetailsCommande', vm.cmd).then(
                    function (response) {
                        if (response.data.success == true) {
                            $scope.$apply();
                            swal(
                                $translate.instant('Succés'),
                                $translate.instant('Commande ajoutée avec succés'),
                                'success'
                            ).then(function () {
                                $window.location.reload();
                            })
                                .then(function () {
                                    $window.location.reload();
                                });
                        }
                    },
                    function (error) {
                        console.log(error);
                    });
            } else {
                swal(
                    $translate.instant('Attention!'),
                    $translate.instant('veuillez bien vérifier les quantités supérieures à stock ou les quantite négative'),
                    'warning'
                )
            }
        } else {
            WS.post('commandes/clotureDetailsCommande', vm.cmd).then(
                function (response) {
                    if (response.data.success == true) {
                        $scope.$apply();
                        swal(
                            $translate.instant('Succés'),
                            $translate.instant('Commande ajoutée avec succés'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        })
                            .then(function () {
                                $window.location.reload();
                            });
                    }
                },
                function (error) {
                    console.log(error);
                });
        }

    };


    vm.clotureDetailsAvoir = function () {
       
        WS.post('entetecommercial/clotureDetailsAvoir', vm.cmd).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succé',
                        'Avoir modifié avec succé',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                }
            },
            function (error) {
                console.log(error);
            });
    };
    vm.refusDetailsAvoir = function () {
        WS.post('entetecommercial/refusDetailsAvoir', vm.cmd).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succé',
                        'Avoir Refusé',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                }
            },
            function (error) {
                console.log(error);
            });
    };


    vm.updateType = function () {
        var data = {
            code: vm.details.code
        };
        swal({
            title: 'Commande',
            text: "voulez vous vraiment Cloturer cette commande!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.post('entetecommercial/updateType', data).then(
                function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            'Commande cloturé avec Succè',
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    }
                },
                function () {
                    swal(
                        'Attention',
                        'vous ne pouvez pas cloturer cette commande!',
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    };

    vm.updateQuantiteCMD = function (prod) {
        var data = {
            code: vm.details.code,
            produit_code: prod.produit_code,
            quantite: prod.quantite
        };

        WS.post('entetecommercial/updateQuantiteCMD', data).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Quantite modifié avec Succè',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                }
            },
            function () {
                swal(
                    'Attention',
                    'erreur de modification de quantite',
                    'warning'
                );
            }
        );
    }

    function roundToThree(num) {
        return +(Math.round(num + "e+3") + "e-3");
    };


    /*
    '*********** 
    ' Devise=0   aucune 
    '       =1   Dinar  
    '       =2   Dollar $ 
    ' Langue=0   Français 
    '       =1   Belgique 
    '       =2   Suisse 
    '*********** 
    ' Conversion limitée à 999 999 999 999 999 ou 9 999 999 999 999,99 
    ' si le nombre contient plus de 2 décimales, il est arrondit à 2 décimales 
    */
    vm.ConvNumberLetter_fr = function (Nombre, bCheckFloat) {
        Nombre = (Nombre) ? Nombre.toFixed(3) : Nombre;
        var strNombre = new String(Nombre);
        var TabNombre = new Array();
        var strLetter = new String();
        if (isNaN(parseFloat(Nombre))) return "";
        if (bCheckFloat) {
            TabNombre = strNombre.split(".");
            if (TabNombre.length > 2 || TabNombre.length <= 0) return "";
            for (var i = 0; i < TabNombre.length; i++) {
                if (i == 0)
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 1, 0);
                else
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 0, 0);
            }
            if (vm.pays.pays == "algerie") {
                return strLetter;
            } else {
                return strLetter + 'Millimes';
            }
        } else {
            strLetter = ConvNumberLetter(Nombre, 1, 0);
            return strLetter;
        }
    }

    function ConvNumberLetter(Nombre, Devise, Langue) {
        var dblEnt, byDec;
        var bNegatif;
        var strDev = new String();
        var strCentimes = new String();

        if (Nombre < 0) {
            bNegatif = true;
            Nombre = Math.abs(Nombre);
        }
        dblEnt = parseInt(Nombre);
        byDec = parseInt((Nombre - dblEnt) * 100);
        if (byDec == 0) {
            if (dblEnt > 999999999999999) {
                return "#TropGrand";
            }
        } else {
            if (dblEnt > 9999999999999.99) {
                return "#TropGrand";
            }
        }
        switch (Devise) {
            case 0:
                if (byDec > 0) strDev = " virgule";
                break;
            case 1:
                if (vm.pays.pays == 'algerie') {
                    strDev = " " + vm.pays.devise;
                } else {
                    strDev = " Dinar";
                }
                if (byDec > 0) strCentimes = strCentimes + " Cents";
                break;
            case 2:
                strDev = " Dollar";
                if (byDec > 0) strCentimes = strCentimes + " Cent";
                break;
        }


        if (vm.pays.pays == 'algerie') {
            if (dblEnt > 1 && Devise != 0) strDev = strDev;
        } else {
            if (dblEnt > 1 && Devise != 0) strDev = strDev + "s et";
        }

        var NumberLetter = ConvNumEnt(parseFloat(dblEnt), Langue) + strDev + " " + ConvNumDizaine(byDec, Langue) + strCentimes;
        return NumberLetter;
    }

    function ConvNumEnt(Nombre, Langue) {
        var byNum, iTmp, dblReste;
        var StrTmp = new String();
        var NumEnt;
        iTmp = Nombre - (parseInt(Nombre / 1000) * 1000);
        NumEnt = ConvNumCent(parseInt(iTmp), Langue);
        dblReste = parseInt(Nombre / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = "mille ";
                break;
            default:
                StrTmp = StrTmp + " mille ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " million ";
                break;
            default:
                StrTmp = StrTmp + " millions ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " milliard ";
                break;
            default:
                StrTmp = StrTmp + " milliards ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " billion ";
                break;
            default:
                StrTmp = StrTmp + " billions ";
        }
        NumEnt = StrTmp + NumEnt;
        return NumEnt;
    }

    function ConvNumDizaine(Nombre, Langue) {
        var TabUnit, TabDiz;
        var byUnit, byDiz;
        var strLiaison = new String();

        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept",
            "huit", "neuf", "dix", "onze", "douze", "treize", "quatorze", "quinze",
            "seize", "dix-sept", "dix-huit", "dix-neuf");
        TabDiz = Array("", "", "vingt", "trente", "quarante", "cinquante",
            "soixante", "soixante", "quatre-vingt", "quatre-vingt");
        if (Langue == 1) {
            TabDiz[7] = "septante";
            TabDiz[9] = "nonante";
        } else if (Langue == 2) {
            TabDiz[7] = "septante";
            TabDiz[8] = "huitante";
            TabDiz[9] = "nonante";
        }
        byDiz = parseInt(Nombre / 10);
        byUnit = Nombre - (byDiz * 10);
        strLiaison = " "; //"-"
        if (byUnit == 1) strLiaison = " et ";
        switch (byDiz) {
            case 0:
                strLiaison = "";
                break;
            case 1:
                byUnit = byUnit + 10;
                strLiaison = "";
                break;
            case 7:
                if (Langue == 0) byUnit = byUnit + 10;
                break;
            case 8:
                if (Langue != 2) strLiaison = " "; //"-"
                break;
            case 9:
                if (Langue == 0) {
                    byUnit = byUnit + 10;
                    strLiaison = " "; //"-"
                }
                break;
        }
        var NumDizaine = TabDiz[byDiz];
        if (byDiz == 8 && Langue != 2 && byUnit == 0) NumDizaine = NumDizaine + "s";
        if (TabUnit[byUnit] != "") {
            NumDizaine = NumDizaine + strLiaison + TabUnit[byUnit];
        } else {
            NumDizaine = NumDizaine;
        }
        return NumDizaine;
    }

    function ConvNumCent(Nombre, Langue) {
        var TabUnit;
        var byCent, byReste;
        var strReste = new String();
        var NumCent;
        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf", "dix");

        byCent = parseInt(Nombre / 100);
        byReste = Nombre - (byCent * 100);
        strReste = ConvNumDizaine(byReste, Langue)
        switch (byCent) {
            case 0:
                NumCent = strReste;
                break;
            case 1:
                if (byReste == 0)
                    NumCent = "cent";
                else
                    NumCent = "cent " + strReste;
                break;
            default:
                if (byReste == 0)
                    NumCent = TabUnit[byCent] + " cents";
                else
                    NumCent = TabUnit[byCent] + " cent " + strReste;
        }
        return NumCent;
    }

    vm.updateParametrageMobile = function () {

        vm.parametrages.catalogue_pdf = vm.fiche[0];
        WS.postwithimg('parametrages/updateParametrageMobile', vm.parametrages, 'POST')
        .then(function (response) {
            if (response.data.success == true) {

                swal(
                    'Succès',
                    'Ligne Modifier avec Succée',
                    'success'
                ).then(function () {
                    $window.location.reload();
                });
            } else {
                swal(
                    'Attention',
                    response.data.message,
                    'warning'
                );
            }
        }, function (error) {
            swal(
                'Attention',
                'erreur de modification',
                'warning'
            );
        });
    }

    vm.updateDateLivraison = function () {
        var data = {
            code: vm.cmd.code,
            date_livraison: vm.cmd.date_prevu_livraison
        };
        console.log(data);
        WS.post('parametrages/updateDateLivraison', data).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Date de livraison Modifié avec Succée',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Attention',
                        response.data.message,
                        'warning'
                    );
                }
            },
            function () {
                swal(
                    'Attention',
                    'erreur de modification',
                    'warning'
                );
            }
        );
    };


    vm.getOneGestionCommande = function () {
        WS.getData('gestion_commandes/getOneGestionCommande', { code: $state.params.id }).then(
            function (response) {

                vm.gestion_cmd = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.affectationImageClient = function (value) {
        //console.log(value);
        var data = {
            visite_code: value.visite_code,
            client_code: value.client_code,
          
        };

        WS.getData('images/affectationImageClient', data).then(
            function (response) {
                if (response.data.success) {
                    vm.getvisiteHorsClient();

                    swal(
                        'Succés',
                        'Client modifié avec succé',
                        'success'
                    );

                } else {
                    swal(
                        'Attention',
                        'erreur de modification',
                        'warning'
                    );
                }
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.affectationPositionClient = function (value) {
        //console.log(value);
        var data = {
            visite_id: value.visite_id,
            client_id: value.client_id,
            longVisite: value.longVisite,
            latVisite: value.latVisite
        };

        WS.getData('parametrages/affectationPositionClient', data).then(
            function (response) {
                if (response.data.success) {
                    vm.getvisiteHorsClient();

                    swal(
                        'Succés',
                        'Position Client modifié avec succé',
                        'success'
                    );

                } else {
                    swal(
                        'Attention',
                        'erreur de modification de position',
                        'warning'
                    );
                }
            },
            function (error) {
                console.log(error);
            }
        );
    };

    $scope.uploadedFiche = function (element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.parametrages.catalogue_pdf = event.target.result
            $scope.$apply(function () {
                vm.fiche = element.files;

            });
        }
        reader.readAsDataURL(element.files[0]);
        $scope.$apply();
    };

    vm.validationCommande = (code) => {
        var data = {code};

        swal({
            title: $translate.instant(`Validation`),
            // text: $translate.instant('code bl') + ` : `,
            html: $translate.instant(`Voulez vous vraiement valider cette commande ?`),
            type: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui, Valider!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.post('commandes/validationCommande', data).then(
                function (response) {
                    if (response.data.success) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('Commande validée avec succés'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });;
                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant(response.data.message),
                            'warning'
                        );
                    }
                },
                function () {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('erreur de chargement des données'),
                        'warning'
                    );
                }
            );

        });
    }

    vm.modifierCommande = (code) => {
        //var data = {code};

        swal({
            title: $translate.instant(`Modification`),
            // text: $translate.instant('code bl') + ` : `,
            html: $translate.instant(`Enregistrer les modifications ?`),
            type: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui, Enregistrer!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            var authorize_call = 0;
            if (vm.global_gestion_stock == 1) {
                _.each(vm.cmd.prod, (element, key) => {
                    if (element.stock < element.quantite) {
                        authorize_call++;
                    }
                });
                if (authorize_call == 0) {
                    WS.post('commandes/modifierCommande', vm.cmd).then(
                        function (response) {
                            if (response.data.success == true) {
                                $scope.$apply();
                                swal(
                                    $translate.instant('Succés'),
                                    $translate.instant('Commande ajoutée avec succés'),
                                    'success'
                                ).then(function () {
                                    $window.location.reload();
                                })
                                    .then(function () {
                                        $window.location.reload();
                                    });
                            }
                        },
                        function (error) {
                            console.log(error);
                        });
                } else {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('veuillez bien vérifier les quantités supérieures à stock ou les quantite négative'),
                        'warning'
                    )
                }
            } else {
                WS.post('commandes/modifierCommande', vm.cmd).then(
                    function (response) {
                        if (response.data.success == true) {
                            $scope.$apply();
                            swal(
                                $translate.instant('Succés'),
                                $translate.instant('Commande ajoutée avec succés'),
                                'success'
                            ).then(function () {
                                $window.location.reload();
                            })
                                .then(function () {
                                    $window.location.reload();
                                });
                        }
                    },
                    function (error) {
                        console.log(error);
                    });
            }

        });
    }

    /* vm.validationCommande = (code) => {
        var data = {code};
        WS.post('commandes/validationCommande', data).then(
            function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Commande validée avec succés'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    }).catch(function () {
                        $window.location.reload();
                    });;
                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }
            },
            function () {
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('erreur de chargement des données'),
                    'warning'
                );
            }
        );
    } */

    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                _.each(vm.soussocietes , element => {
                    vm.allsocietes[element.code] = element.nom;
                })

                if(vm.soussocietes.length == 1){
                    vm.soussociete_code = vm.soussocietes[0].code;
                    vm.getproduits();
                }
                
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.getproduits = function() {

        WS.getData('mouvements/names', {soussociete_code : vm.soussociete_code})
            .then(function(response) {
                // vm.chargement = {};
                // vm.chargement.ch = {};
                vm.produits = response.data.produits;
                vm.loading = false;

                // _.each(vm.produits, (prod, indprod) => {
                //     vm.chargement[prod.code] = {};
                //     vm.chargement[prod.code]['retour_hier_p'] = 0;
                //     vm.chargement[prod.code]['quantite'] = 0;
                //     vm.chargement[prod.code]['produit_code'] = prod.code;
                //     vm.chargement[prod.code]['colisage'] = prod.colisage;
                //     vm.chargement[prod.code]['prix_ht'] = prod.prix_ht;
                //     vm.chargement[prod.code]['prix_ttc'] = prod.prix_ttc;
                //     vm.chargement[prod.code]['prix_achat_ht'] = prod.prix_achat_ht;
                //     vm.chargement[prod.code]['prix_achat_ttc'] = prod.prix_achat_ttc;
                //     vm.chargement[prod.code]['tva'] = prod.tva;
                //     vm.chargement[prod.code]['hors_box'] = prod.hors_box;
                //     vm.chargement[prod.code]['stk_quantite'] = prod.stk_quantite;
                //     vm.chargement[prod.code]['produit_id']=prod.id;
                // });


               
                $scope.$apply();
                //vm.total_Casier_chargement();



            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getPuProduct = function (index, produit_code) {
        if(vm.soussociete_code){
            WS.getData('produits/getPuProduct', {
                produit_code: produit_code,
                client_code: vm.details.client.code,
                depot_code: vm.details.depot_code,
                soussociete_code : vm.details.soussociete_code
            })
                .then(
                    function (response) {
                        vm.new_product.produit_libelle = response.data.libelle;
                        vm.new_product.prix_ttc = response.data.prix_ttc;
                        vm.new_product.pu_ttc = response.data.prix_ttc;
                        vm.new_product.prix_ht = response.data.prix_ht;
                        vm.new_product.pu_ht = response.data.prix_ht;
                        vm.new_product.stock = response.data.stock;
                        vm.new_product.stock_carton = response.data.stock / response.data.colisage;
                        vm.new_product.stock_carton = vm.new_product.stock_carton.toFixed(2);
                        vm.new_product.colisage = response.data.colisage;
                        vm.new_product.tva = response.data.tva;
                        vm.new_product.prix_achat_ht = response.data.prix_achat_ht;
                        vm.new_product.prix_achat_ttc = response.data.prix_achat_ttc;
                        vm.new_product.quota = response.data.quota;
                        if(vm.new_product.type != 'gratuite'){
                            vm.new_product.remise = 0;
                        }
                        vm.new_product.quantite = 0;
                        vm.new_product.total = 0;
                        vm.new_product.net_a_payer = 0;
                        
                        //console.log('before', vm.produits);
                        vm.recalculateLigne();
                        //vm.renewListProd();

                        $scope.$apply();

                    },
                    function (error) {
                        console.log(error);
                    });
        }else{
            vm.new_product.produit_code = "";
            Flash.create('danger', $translate.instant("Sélectionner le distributeur avant de choisir les produits"));
        }
    };

    vm.checkGratuiter = function (index, produit_code, quantite) {
        if (quantite > 0) {
            var url = 'produits/checkRemise/' + produit_code + '/' + quantite;
        } else {
            var url = 'produits/checkRemise/' + produit_code + '/' + 0;
        }
        // check quota 
        vm.pass_quota_error = 0;
        if(vm.use_quota==1){
            console.log("check quota")
            if(Number(vm.new_product.quota) < Number(vm.new_product.quantite)){
                vm.pass_quota_error = vm.pass_quota_error+1;
                console.log(vm.new_product.quota,' < ',vm.new_product.quantite)
            }
        }
        
        WS.get(url).then(
            function (response) {
                vm.new_product.casier = roundToThree(vm.new_product.quantite / vm.new_product.colisage);
                if(vm.new_product.type != 'gratuite'){
                    vm.new_product.remise = response.data.remise;
                }
                vm.recalculateLigne(index);

                $scope.$apply();

                var data = {
                    produit_code,
                    quantite : (quantite>0) ? quantite : 0,
                    net_a_payer: vm.new_product.total_net_ttc,
                    allprods : [vm.new_product]
                }

                var url = 'produits/checkGratuiterPost';
                WS.post(url, data).then(
                    function (response) {
                        console.log('response', response);
                        if (vm.gratuites && vm.gratuites.length > 0 && vm.gratuites[index]) {
                            var mod = _.omit($parse("vm.gratuites")($scope), index);
                            $parse("vm.gratuites").assign($scope, mod);
                            vm.gratuites = $.map(vm.gratuites, function (value) {
                                return [value];
                            });
                        }

                        vm.temporary_products = response.data;
                        if (response.data.length > 0) {
                            var alert = `<div class="alert alert-success" role="alert">
                                           
                                  <h4>` + $translate.instant('Produit(s) gratuit(s)') + ` : </h4>`;

                            if (response.data.length == 1) {
                                vm.gratuites[index] = response.data;
                                response.data.forEach(function (element) {
                                    alert += `
                                    <div id="grat-prod-${index}-${element.produit_code}">
                                        <div class="clearfix"></div>
                                        <div>
                                            <div class="pull-left">
                                                <i class="fa fa-minus" ng-click="vm.updateQteGratuite('${element.produit_code}', ${index}, -1);"
                                                   style="color: red;font-size: 18px;margin-top: 2px;margin-left: 10px;margin-right: 7px;cursor: pointer;"></i>
                                            </div>
                                            <div class="pull-left" ng-init="vm.quantites['${index}-${element.produit_code}'].quantite=${element.quantite}">
                                                <input type="number" class="text-center"
                                                       ng-model="vm.quantites['${index}-${element.produit_code}'].quantite" 
                                                       style="width: 49px;font-size: 13px;margin-top: -2px;">
                                            </div>
                                            <div class="pull-left">
                                                <i class="fa fa-plus" ng-click="vm.updateQteGratuite('${element.produit_code}', ${index}, 1);"
                                                    style="color: blue;font-size: 18px;margin-top: 2px;margin-left: 5px;margin-right: 12px;cursor: pointer;"></i>
                                            </div>
                                            <h4 class="pull-left" style="margin-top: 3px;">${element.produit_code} ${element.gratuit_libelle} </h4>
                                            <div class="pull-left">
                                                <i class="fa fa-trash" ng-click="vm.removeGratuite('${element.produit_code}', ${index});"
                                                    style="color: red;font-size: 18px;margin-top: 1px;margin-left: 10px;cursor: pointer;"></i>
                                            </div>
                                         </div>
                                         <div class="clearfix"></div>
                                    </div>`;
                                });
                                alert += `</div>`;
                                alert = $compile(alert)($scope);
                                $('#block-grat-' + index).empty();
                                $('#block-grat-' + index).append(alert);
                            } else {
                                //panel shoose products
                                alert += `<h5 style="color:red!important;"> 
                                                ${$translate.instant('Vous avez plusieurs produits gratuits')} 
                                                <span style="color:blue!important;cursor:pointer;" 
                                                      ng-click="vm.showPanelProduitGratuit(${index});"> ${$translate.instant('cliquez ici')}  </span>
                                                ${$translate.instant('pour sélectionner')}
                                          </h5>
                                    </div>`
                                alert = $compile(alert)($scope);
                                $('#block-grat-' + index).empty();
                                $('#block-grat-' + index).append(alert);
                            }

                        } else {
                            $('#block-grat-' + index).empty();
                        }
                        console.log('checkGratuiter',vm.gratuites);

                        


                        // vm.newbl.gratuites = vm.newbl.gratuites.filter(function(el) { 
                        //     console.log(el,  _.isArray(el));
                        //     return _.isArray(el); 
                        // });
                        console.log('resultGratuite',vm.gratuites);

                        $scope.$apply();
                    },
                    function (error) {
                        console.log(error);
                    });
            },
            function (error) {
                console.log(error);
            });
    };

    vm.recalculateLigne = (index) => {
        if(vm.parametrages.change_prix_cmd){
            vm.new_product.prix_ht = vm.new_product.prix_ttc / (1 + (vm.new_product.tva / 100))
            vm.new_product.prix_ht = parseFloat(vm.new_product.prix_ht).toFixed(3);
            vm.new_product.prix_ht = Number(vm.new_product.prix_ht);
            vm.new_product.pu_ht = Number(vm.new_product.prix_ht);
        }
        
        //vm.new_product.remise = response.data.remise;
        vm.new_product.total_ttc = (vm.new_product.quantite) ? roundToThree(vm.new_product.quantite * vm.new_product.prix_ttc) : 0;
        vm.new_product.total_net_ttc = roundToThree(vm.new_product.total_ttc - ((vm.new_product.remise * vm.new_product.total_ttc) / 100));
        vm.new_product.total_ht = (vm.new_product.quantite) ? roundToThree(vm.new_product.quantite * vm.new_product.prix_ht) : 0;
        vm.new_product.total_net_ht = roundToThree(vm.new_product.total_ht - ((vm.new_product.remise * vm.new_product.total_ht) / 100));

        vm.new_product.p_tva = roundToThree(((vm.new_product.quantite * vm.new_product.prix_ht) -
            ((vm.new_product.remise / 100) * (vm.new_product.quantite * vm.new_product.prix_ht))
        ) * (vm.new_product.tva / 100));

        
        // calcul total
        // vm.newbl.totalRemiseTtc = 0;
        // vm.newbl.totalTtc = 0;
        // vm.newbl.totalNetTtc = 0;

        // vm.newbl.totalRemiseHt = 0;
        // vm.newbl.totalHt = 0;
        // vm.newbl.totalNetHt = 0;

        // vm.newbl.totalTva = 0;

        // angular.forEach(vm.newbl.lignes, function (value, key) {
        //     // TTC
        //     vm.newbl.totalTtc = roundToThree(vm.newbl.totalTtc + value.total_ttc);
        //     vm.newbl.totalNetTtc = roundToThree(vm.newbl.totalNetTtc + value.total_net_ttc);
        //     // HT
        //     vm.newbl.totalHt = roundToThree(vm.newbl.totalHt + value.total_ht);
        //     vm.newbl.totalNetHt = roundToThree(vm.newbl.totalNetHt + value.total_net_ht);

        //     //tva
        //     vm.newbl.totalTva = roundToThree(vm.newbl.totalTva + value.p_tva);

        // });
        // vm.newbl.totalRemiseHt = roundToThree(vm.newbl.totalHt - vm.newbl.totalNetHt);
        // vm.newbl.totalRemiseTtc = roundToThree(vm.newbl.totalTtc - vm.newbl.totalNetTtc);
    }

    vm.total_Casier_chargement = function() {
        /**
         * total caisse
         */
        vm.total_casier_charg = 0;

        var caisse = _.find(vm.produits, function(item) { //\\
            return (item.code == 'CAISSE' || item.code.toLowerCase() == 'ga'); //  \\
        }); //    \\
        if(caisse){
            vm.chargement.ch[caisse.id].quantite = 0;
        }

        vm.total_casier_charg = _.reduce(vm.chargement['ch'], function(value, prod) {
            if (typeof prod === 'object') {
                console.log(value, prod);
                /* if(vm.chargement.ch.retour_hier){
                     return value + ((Math.round(Number(prod.quantite) + Number(prod.retour_hier_p)) / prod.colisage)); 
                     
                 }else{
                 */
                if (prod.hors_box == 0) {
                    if(vm.chargement['ch'][prod.produit_id]){
                        if (vm.chargement['ch'][prod.produit_id]['quantite'] != undefined && vm.chargement['ch'][prod.produit_id]['quantite'] != NaN) {
                            if (vm.chargement.ch.colisage == false) {
                                return value + (Math.round(Number(vm.chargement['ch'][prod.produit_id]['quantite']) / prod.colisage));
                            } else {
                                return value + Math.round(Number(vm.chargement['ch'][prod.produit_id]['quantite']));
                            }
                        } else {
                            return value;
                        }
                    }
                } else {
                    return value;
                }

                // }
            } else {
                console.log(value);

                return value;

            }
        }, 0);


        /////////////// append vm.total_casier_charg to input caisse //////////////////\                            
        //      \\
        if (caisse) { //        \\
            vm.chargement.ch[caisse.id].quantite = vm.total_casier_charg; //          \\
        } //   DEVAPP   \\
        ////////////////////////////////////////////////////////////////////////==============\\

        /**
         * montant total
         */
        vm.total_montant_charg = 0;
        vm.total_montant_charg = _.reduce(vm.chargement['ch'], function(value, prod) {
            if (typeof prod === 'object') {
                // console.log(value, prod);

                if (vm.chargement.ch.retour_hier) {
                    return value + ((Number(prod.quantite) + Number(prod.retour_hier_p)) * prod.prix_ttc);

                } else {
                    if (prod.quantite != undefined && prod.quantite != NaN) {
                        return value + Number(prod.quantite) * prod.prix_ttc;
                    } else {
                        return value;
                    }
                }
            } else {
                return value;
            }
        }, 0);
        //vm.total_montant_charg.toFixed(3);
    };

    vm.addNewProd = () => {
        vm.new_product.entetecommercial_code = vm.cmd.code;
        console.log(vm.new_product);
        WS.post('commandes/addNewProd', vm.new_product).then(
            function (response) {
                if (response.data.success) {

                    //vm.cmd.prod.push(vm.new_product);
                    vm.total_montant_ttc = vm.total_montant_ttc + vm.new_product.total_ttc;
                    vm.total_remises = vm.total_remises + (vm.new_product.total_ttc - vm.new_product.total_net_ttc);
                    vm.total_net_a_payer = vm.total_net_a_payer + vm.new_product.total_net_ttc;
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('produit ajoutée avec succés'),
                        'success'
                    ).then(function () {}).catch(function () {});
                    vm.new_product.bgcolor=true;
                    vm.cmd.prod.push(vm.new_product);
                    vm.new_product = {};
                    $scope.$apply();

                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }
            },
            function () {
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('erreur de chargement des données'),
                    'warning'
                );
            }
        );

        
    }

    vm.changetypeProd = () =>{
        if(vm.new_product.type=='gratuite') {
            vm.new_product.remise = 100;
        }else{
            vm.new_product.remise = 0;
        }
    }

}
export default {
    name: 'parametrageController',
    fn: parametrageController
};